import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("库区编号"),
    dataIndex: "number",
    key: "number",
    sorter: true,
  },
  {
    title: T("库区名称"),
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
    key: "warehouse_name",
    customRender: (value, item, index) => {
      return value ? value : "null";
    },
  },
  {
    title: T("状态"),
    dataIndex: "is_active",
    key: "is_active",
    scopedSlots: { customRender: "is_active" },
  },
  {
    title: T("备注"),
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
